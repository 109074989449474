/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

rating-chart-height = 300px;

.RatingsChartDistribution
    height: rating-chart-height
    max-width: calc(100vw - 2em)
    h4
        text-align: center
    .wrapper
        height: rating-chart-height
        position: relative

    .graphContainer
        height: rating-chart-height
        position: absolute
        top: 0
        width: 100%
        z-index: 0

    .secondGraph
        height: rating-chart-height
        position: absolute
        top: 0
        width: 100%
        z-index: 4

    .loading
        display: flex
        justify-content: center
        align-items: center
        height: rating-chart-height