/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
navbar-width = 18rem;
submenu-min-height = 9rem;
hamburger-cutoff = 900px;

header.NavBar {
    /body.zen & {
        display: none;
    }

    display: flex;
    margin-bottom: 0.2rem;
    height: navbar-height;
    user-select: none;
    themed: background-color navbar-background-color;
    box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.16);
    justify-content: space-between;
    max-width: 100vw;

    .fa-star {
        // themed color supporter-gold
        color: supporter-gold;
    }

    &>.left, &>.right {
        flex: 1rem;
        flex-grow: 0;
        flex-shrink: 0;
    }

    .center {
        flex: 1rem;
        flex-grow: 1;
        flex-shrink: 0;
        flex-align: flex-start;
    }

    .mobile-only {
        display: none !important;
    }

    &.force-nav-close {
        .Menu-children {
            display: none !important;
        }
    }

    .Menu-children {
        /* reset <ul> */
        margin: 0;
        padding: 0;
        list-style: none;

        .MenuLink, .MenuLink > * {
            display flex;
            align-items: center;
        }

        .MenuLink.centered {
            justify-content: center;
        }
    }

    /* desktop / wide screens */
    @media (min-width: (hamburger-cutoff + 1px)) {
        .Menu {
            display: block;
            margin-left: 0.5rem;
            position: relative;
            height: navbar-height;

            .Menu-children {
                display: none;
                position: absolute;
                left: 0;
                top: navbar-height;
                min-width: navbar-width;
                themed: background-color navbar-background-color;
                // border-left: 0.2rem solid transparent;
                box-shadow: 2px 4px 3px 0px rgba(0, 0, 0, 0.16);
                z-index: z.nav-menu.menu;
            }

            .MenuLink:hover {
                themed: background-color shade4;
            }

            &:hover, &.active {
                themed: background-color navbar-highlight-background-color;
                themed: border-color primary;

                .Menu-children {
                    display: inline-flex;
                    flex-direction: column;
                    themed: border-color primary;
                    themed: background-color navbar-highlight-background-color;
                }
            }

            .submenu {
                display: none;
            }

            .submenu-container:hover .submenu {
                display: inline-flex;
                flex-direction: column;
                position: absolute;
                top: 0;
                min-height: submenu-min-height;
                left: navbar-width;
                themed: background-color shade4;
                min-width: navbar-width;
                box-shadow: 2px 4px 3px 0px rgba(0, 0, 0, 0.16);

                > a {
                    display: inline-flex;
                    align-items: center;
                }
            }

            &.profile {
                display: inline-flex;
                flex-direction: column;

                .NotificationList {
                    margin-bottom: 1rem;
                }

                .theme-selectors-container {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    margin-top: 0.5rem;
                }

                .theme-selectors {
                    display: flex;
                    justify-content: space-around;
                }

                .settings-and-logout {
                    margin-top: 0.5rem;
                    margin-bottom: 0.5rem;
                    margin-top: 1rem;
                    display: flex;
                    justify-content: space-around;
                }

                &:hover, &.active {
                    themed: background-color navbar-highlight-background-color;
                    themed: border-color primary;

                    .Menu-children {
                        flex-direction: column;
                        border-color: clear !important;
                        background-color: none !important;
                    }
                }

                .Menu-children {
                    min-width: 20rem;
                    right: 0;
                    left: auto;
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .FriendIndicator, .IncidentReportIndicator, .TournamentIndicator, .TurnIndicator, .NotificationIndicator, .ChatIndicator, .GoTVIndicator {
            padding-left: 0.3rem !important;
            padding-right: 0.3rem !important;
        }
    }

    .Menu-title {
        transition: all 0.5s ease;
    }

    @media only screen and (max-width: 1060px) {
        .Menu-title {
            padding-left: 0.3rem !important;
            padding-right: 0.3rem !important;
        }
    }

    @media only screen and (max-width: 980px) {
        .Menu-title {
            padding-left: 0.2rem !important;
            padding-right: 0.2rem !important;
        }
    }

    @media only screen and (max-width: 910px) {
        .Menu-title {
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
        }
    }

    /* mobile */
    @media only screen and (max-width: hamburger-cutoff) {
        &>.left {
            display: none !important;
        }

        .Menu.desktop-only {
            display: none !important;
        }

        .icon-container.mobile-only {
            display: flex !important;
        }

        .Menu.mobile-only {
            display: flex !important;

            img.PlayerIcon {
                margin-left: 0.5rem;
            }
        }

        .icon-container {
            padding-right: 0 !important;

            img.PlayerIcon {
                margin-right: 0 !important;
            }

            .username {
                display: none;
            }
        }

        .ogs-nav-logo {
            height: 1.5rem;
            width: 4rem !important;
            margin-right: 0 !important;
        }

        .OmniSearch-container {
            // display: none !important;
            display: inline-flex !important;
            min-width: 2rem;

            &:focus-within {
                width: calc(100vw - 5rem) !important;
                // min-width: calc(100vw - 10rem) !important;
                max-width: auto;
                margin-right: 0.5rem;

                .OmniSearch-input-container {
                    max-width: 100%;
                }
            }

            .fa-search {
                font-size: 1.2rem;
            }
        }

        .right {
            flex: 0rem;

            &.search-focused {
                display: none;

                @media only screen {
                    display: inline-flex;
                }
            }
        }

        .center {
            flex: 0rem;
        }

        .spacer {
            flex: 1;
            flex-basis: 2rem;
        }

        .TournamentIndicator.hidden-on-mobile {
            display: none;
        }

        &.hamburger-expanded {
            .OmniSearch-container {
                display: inline-flex !important;
            }

            .ogs-nav-logo {
                display: none;
            }

            .right {
                display: none;
            }

            .OpenMenuButton {
                display: none;
            }
        }

        &.hamburger-expanded>.left {
            display: inline !important;

            themed: background-color navbar-background-color;
            position: absolute;
            top: navbar-height;
            bottom: 0;
            left: 0;
            padding: 0;
            // max-height: "calc(100vh - %s)" % navbar-height;
            // overflow-y: auto;
            z-index: z.nav-menu.menu;

            &>ul {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            .ogs-nav-logo {
                display: none;
            }

            .Menu {
                order: 2;
                width: 100vw;
                display: flex;
                flex-direction: column;
                height: auto;
                themed-important: background-color navbar-background-color;
            }

            .Menu-title {
                font-size: font-size-big;
                white-space: nowrap;
            }

            .Menu-children {
                display: inline-flex;
                flex-direction: column;
                position: static;
                display: inline-flex;
                flex-direction: column;
                box-shadow: none;
                flex-shrink: 0;
                themed-important: background-color navbar-background-color;
                padding-left: 2rem;
            }

            .submenu-container {
                display: flex;
                flex-direction: column;

                .submenu {
                    flex-shrink: 0;
                    display: inline-flex;
                    flex-direction: column;
                    position: static;
                    box-shadow: none;
                    min-height: auto !important;
                    themed-important: background-color navbar-background-color;
                    padding-left: 2rem;
                }
            }
        }
    }

    @media only screen and (min-width: 528px) {
        .right {
            &.search-focused {
                display: inline-flex !important;
            }
        }
    }

    .hamburger {
        display: none;
        cursor: pointer;

        @media only screen and (max-width: hamburger-cutoff) {
            font-size: 1.7rem;
            display: inline-flex;
            align-items: center;
            padding-left: 0.5rem;

            a {
                display: inline-flex;
                align-items: center;
            }
        }
    }

    .Menu {
        img {
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 0.5rem;
        }

        .Menu-children {
            img, .fa, .ogs-goban {
                width: 16px;
                max-width: 16px;
                height: 16px;
                text-align: center;
                font-weight: 400;
                margin-left: 0.5rem;
                margin-right: 0.5rem;

                &.fa-graduation-cap {
                    font-size: 14px;
                }
            }
        }
    }

    button.MenuLink {
        background: transparent;
        outline: none;
        border:none;
        border-radius: 0;
        width: 100%;
        text-align: left;
        margin: 0;
        padding: 0;
        box-shadow: none;

        &:focus {
            box-shadow: 0 0 0 2px rgba(255, 255, 255, 0), 0 0px 0px 2px #4c9ffe;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .MenuLink, a, .clickable {
        themed: color text-color;
        transition: background .2s;
    }

    .MenuLink, button.MenuLink, a, .clickable {
        &:active, &:hover {
            themed: color text-color-active;

            // underline only on the text (not the icon)
            text-decoration: none;
            .MenuLinkTitle {
                text-decoration: underline;
            }
        }
    }

    .ogs-nav-logo {
        height: 1.5rem;
        width: 4rem;
        margin-right: 1rem;
    }

    >.left {
        & > ul {
            display: flex;
            align-items: center;
            margin: 0;
            padding: 0;
            list-style: none;
        }
    }

    >.left, .Menu {
        .Menu-title {
            display: inline-flex;
            line-height: navbar-height;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            white-space: nowrap;
            align-items: center;
        }

        .MenuLink {
            height: navbar-height;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }
    }

    .right {
        flex-shrink: 0;
        display: flex;
        align-items: center;

        .icon-container {
            display: flex;
            position: relative;
            padding-left: 0.3rem;
            padding-right: 0.7rem;
            cursor: pointer;
            align-items: center;

            .username {
                white-space: nowrap;
            }

            .notification-indicator {
                left: 0.5rem;
                width: navbar-height;
            }

            img.PlayerIcon {
                // height: navbar-height;
                // width: navbar-height;
                height: 2rem;
                width: 2rem;
                pointer-events: none;
                margin-right: 0.5rem;
            }

            .fa, .ogs-goban {
                font-size: 1rem;
                font-weight: 400;
                margin-left: 0.5rem;
                themed: color shade1;
            }

            &:hover .fa {
                themed: color fg;
            }
        }
    }

    .fa-adjust {
        padding: 0.6rem;
        margin-bottom: -0.1rem;
        cursor: pointer;
    }

    /body.dark .fa-adjust {
        transform: rotate(180deg);
    }

    // Since accessible is based on dark, I assume it needs this (not clear what this is specifically for, though the general idea is clear)
    /body.accessible .fa-adjust {
        transform: rotate(180deg);
    }

    .sign-in {
        padding-right: 1rem;
        white-space: nowrap;
    }

    .nav-menu-modal-backdrop {
        display: none;
        position: fixed;
        z-index: z.nav-menu.backdrop;
        top: navbar-height;
        bottom: 0;
        left: 0;
        right: 0;

        // transition: background-color 0.4s ease;
        // background-color: transparent;
        &.active {
            display: inline-block;
            // background-color: rgba(128,128,128,0.2);
        }
    }

    .RightNav {
        display: inline-flex;
        flex-direction: column;
        position: absolute;
        right: 0;
        top: navbar-height;
        width: navbar-width;
        max-width: 100vw;
        max-height: 'calc(100vh - %s)' % navbar-height;
        themed: background-color navbar-background-color;
        overflow-y: auto;
        user-select: none;
        z-index: z.nav-menu.menu;
        box-shadow: -2px 2px 1px 0px rgba(0, 0, 0, 0.16);
        list-style: none;
        margin: 0;
        padding: 0;

        .NotificationList {
            margin-bottom: 1rem;
        }

        .theme-selectors-container {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            margin-top: 0.5rem;
        }

        .theme-selectors {
            display: flex;
            justify-content: space-around;
        }

        .settings-and-logout {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            margin-top: 1rem;
            display: flex;
            justify-content: space-around;
        }

        .MenuLink {
            margin: 0.5rem;
            display: inline-flex;
            align-items: center;

            img, i {
                margin: 0.2rem;
                margin-right: 0.5rem;
            }
        }
    }

    .NotificationList {
        position: absolute;
        right: 0;
        top: navbar-height;
        width: navbar-width;
        themed: background-color navbar-background-color;
        max-height: 'calc(100vh - %s)' % navbar-height;
        overflow-y: auto;
        padding: 0.5rem;
        z-index: z.nav-menu.menu;
        box-shadow: -2px 2px 1px 0px rgba(0, 0, 0, 0.16);
    }

    .FriendList {
        top: navbar-height;
        max-height: 'calc(100vh - %s)' % navbar-height;
        themed: background-color navbar-background-color;
    }

    .FriendIndicator, .IncidentReportIndicator, .TournamentIndicator, .TurnIndicator, .NotificationIndicator, .ChatIndicator, .GoTVIndicator {
        padding-left: 0.6rem;
        padding-right: 0.6rem;
    }

    .spacer {
        width: auto;
    }

    .ChatIndicator {
        display: inline-flex;
        align-self: center;
        line-height: 1rem;

        .navbar-icon {
            margin-bottom: 0px !important;
        }
    }

    .FriendIndicator, .IncidentReportIndicator, .NotificationIndicator, .TournamentIndicator, .GoTVIndicator {
        .count, .time {
            // This offsets the baseline padding added to the count so things center correctly.
            margin-bottom: -0.2rem;
        }
    }

    .ChatList {
        themed: background-color navbar-highlight-background-color;
        padding: 0.5rem;
        height: 'calc(100vh - %s)' % navbar-height;
        max-height: 'calc(100vh - %s)' % navbar-height;
        width: 15rem;

        .channels {
            margin-top: 0.5rem;
        }

        .channel {
            padding-top: 0.25rem;
            padding-bottom: 0.25rem;
            height: 1.2rem;

            .channel-name {
                display: inline-flex;
                align-items: center;
                align-content: center;

                .fa-trophy, .icon {
                    margin-right: 0.5rem;
                }
            }
        }
    }

    .ThemeMenu {
        h4 {
            margin: 0;
            text-align center;
            font-weight:normal;
        }
    }
}

.OmniSearch-container {
    position: relative;
    height: navbar-height;
    display: inline-flex;
    align-items: center;
    // justify-content: center;
    padding-left: 0.5rem;
    padding-bottom: 0;
    flex-grow: 1;
    flex-shrink: 0;
    min-width: 7rem;

    .OmniSearch-input-container {
        display: inline-flex;
        align-items: center;
        flex: 1;
        max-width: 20rem;
        position: relative;

        label {
            margin-right: 0.3rem;
            position: absolute;
            left: 0.2rem;
            cursor: pointer;
        }
    }

    .OmniSearch-input {
        flex: 1;
        flex-shrink: 1;
        width: 100%;
        padding-left: 1.5rem;
    }

    .OmniSearch {
        position: absolute;
        // top: navbar-height;
        // top: -0.15rem;
        // top: 0;
        top: navbar-height;
        // left: navbar-width;
        width: navbar-width;
        themed: background-color navbar-highlight-background-color;
        box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.16);
        z-index: z.nav-menu.menu;
        max-height: 'calc(100vh - %s)' % navbar-height;
    }
}

button.theme-button {
    cursor: pointer;
    inactive-button();
    border: 1px solid transparent;
    padding: 0.3rem;
    padding-right: 1rem;
    padding-left: 1rem;
    font-size: 1.2rem;
}

body.light header.NavBar button.theme-button.light {
    background-color: light.primary;
    border-color: darken(light.primary, 8%);

    i.fa.fa-sun-o {
        color: #fff;
    }
}

body.dark header.NavBar button.theme-button.dark {
    background-color: dark.primary;
    border-color: darken(dark.primary, 8%);

    i.fa.fa-moon-o {
        color: #fff;
    }
}

// do for accessible what we do for dark...
body.accessible header.NavBar button.theme-button.accessible {
    background-color: accessible.primary;
    border-color: darken(accessible.primary, 8%);

    i.fa.fa-eye {
        color: #fff;
    }
}

.BanIndicator {
    position: fixed;
    right: 0;
    top: navbar-height;
    themed: background-color reject;
    z-index: z.banned-banner;
    width: 15rem;
    themed: color colored-background-fg;
    padding: 1rem;

    > div {
        font-size: 1.2rem;
        text-align: center;
        padding-top: 1rem;

        a {
            themed: color link-color;
        }
    }
}
