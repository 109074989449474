.Supporter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
        width: 100vw;
        max-width: 40rem;
    }

    .Payments {
        display: grid;
    }

    .Payment {
        // display: grid-row;
        // display: flex;
        // flex-direction: row;
        // justify-content: center;
        // align-items: center;
        .fa-check {
            color: green;
        }

        .fa-times {
            color: red;
        }

        .date {
            display: inline-block;
            width: 12rem;
            text-align: right;
        }

        .amount {
            display: inline-block;
            width: 6rem;
            text-align: right;
            margin-right: 3rem;
        }
    }

    .PaymentMethod {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .fa-lock {
            color: green;
        }

        span {
            display: inline-block;
            height: 1.5rem;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            word-wrap: nowrap;
        }

        .stripe, .paddle, .paypal {
            border-radius: 0.3rem;
            background-repeat: no-repeat;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            display: inline-block;
            width: 8rem;
        }

        .last4, .expiration {
            display: inline-block;
            themed: background-color shade5;
            width: 4rem;
            text-align: center;
            border-radius: 0.3rem;
            padding-right: 0.3rem;
            padding-left: 0.3rem;
        }
    }

    .prize-status {
        themed: color danger;
    }

    .Subscription {
        text-align: center;

        h3 {
            text-align: left;
        }

        button {
            margin: 0.5rem;
        }
    }

    .Prices {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 1rem;

        .PriceBox:nth-of-type(2) {
            box-shadow: 0rem 0.3rem 0.8rem #000;

            /body.light .PriceBox:nth-of-type(2) {
                box-shadow: 0rem 0.3rem 0.8rem #888 !important;
            }
        }
    }

    .PriceBox {
        display: inline-flex;
        flex-direction: column;
        width: 20rem;
        max-width: 95vw;
        // themed background-color shade3
        // margin: 1rem;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
        // padding: 0.5rem;
        text-align: center;
        border-radius: 0.7rem;
        font-family: 'Tahoma', sans-serif;
        background-color: #0097C7;
        color: #ffffff;
        box-shadow: 0.2rem 0.2rem 0.7rem #000;

        /body.light .PriceBox {
            box-shadow: 0.2rem 0.2rem 0.9rem #888 !important;
        }

        // body.dark {
        // }
        h3, p {
            width: 100%;
        }

        p {
            flex: 1;
        }

        .already-supporter {
            display: block;
            margin: 0.5rem;
            min-height: 6rem;
        }

        ul {
            flex: 1;
            max-width: 15rem;
            text-align: left;

            li {
                list-style-type: none;
                margin: 0.5rem;
                position: relative;

                &:before {
                    font-family: 'FontAwesome';
                    content: '\f00c';
                    margin-left: -2rem;
                    position: absolute;
                }
            }
        }

        > h1 {
            font-size: 1.6rem;
            background-color: #007CA3;
            color: #ffffff;
            margin: 0;
            border-top-left-radius: 0.7rem;
            border-top-right-radius: 0.7rem;
            padding: 0.7rem;
        }

        b.green {
            color: #99FB21;
        }
    }

    .DeprecatedPlanNote {
        background-color: #0097C7;
        max-width: 40rem;
        text-align: center;
        border-radius: 0.7rem;
        font-family: 'Tahoma', sans-serif;
        background-color: #0097C7;
        color: #ffffff;
        padding: 1rem;
    }

    .SiteSupporterText {
        width: 100vw;
        max-width: 50rem;
        text-align: justify;

        p {
            padding: 1rem;
        }
    }

    .price-increase-note {
        font-style: italic;
    }

    .annual-billing {
        display: flex;
        align-items: center;
        justify-items: center;
        align-content: center;

        label {
            font-size: 1.1rem;
            font-weight: bold;
            margin-right: 1rem;
        }
    }

    .payment-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        align-content: center;

        .ruler {
            background-color: #007CA3;
            height: 0.1rem;
            width: 100%;
            margin-top: 0.4rem;
            margin-bottom: 0.4rem;
        }

        button {
            display: inline-flex;
            align-items: center;
            align-content: center;
            // width: 8rem;
            font-size: 1.2rem;
            font-weight: bold;
            background-color: #FFC439;

            i {
                margin-left: 0.5rem;
            }

            &.sign-up, &.paddle-sign-up {
                background-color: #67478A;
                color: #fff;

                &:hover {
                    border-color: #67478A;
                    background-color: lighten(#67478A, 10%);
                }
            }

            &.paypal-button {
                margin-left: 0.5rem;
            }
        }

        padding-bottom: 1rem;
    }

    .developer-options {
        text-align: center;
        color: #000;
        padding: 1rem;
        width: 100%;
        font-weight: bold;
        max-width: 40rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        background-image: repeating-linear-gradient(135deg, #888, #888 30px, #FFA100 30px, #FFA100 60px);

        label {
            margin-left: 0.5rem;
            padding-right: 0.5rem;
            min-width: 3rem;
            display: inline-block;
        }

        dl {
            text-align: left;
            background-color: rgba(255, 255, 255, 0.5);
            padding: 0.5rem;
        }

        input {
            color: #000 !important;
        }

        input[type='text'] {
            width: 4rem;
            margin-left: 0.5rem;
        }

        input#paddle_promo_code {
            width: 8rem;
        }
    }

    .Service {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 1rem;
    }
    
    
    .highlight {
        color: #99FB21;
        font-weight: bold;
    }
}

.payment-method {
    display: inline-block;
    background-size: contain;
    width: 2rem;
    height: 2rem;
    border-radius: 0.3rem;
    margin: 0.3rem;
    background-color: #fff;

    &.card {
        background-image: svg-load('../assets/payment_methods/card.svg');
    }

    &.apple {
        background-image: svg-load('../assets/payment_methods/apple.svg');
    }

    &.google {
        background-image: svg-load('../assets/payment_methods/google.svg');
    }

    &.bank {
        background-image: svg-load('../assets/payment_methods/bank.svg');
    }

    &.sepa {
        background-image: svg-load('../assets/payment_methods/sepa.svg');
    }
}

