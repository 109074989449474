/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.FriendList {
    .show-offline {
        input, label {
            cursor: pointer;
        }

        padding-bottom: 0.5rem;
        display: flex;
        align-items: center;
    }

    .friend-invitation {
        display: flex;

        .fa {
            margin-right: 0.5rem;
            cursor: pointer;
        }

        .fa-check {
            themed: color success;
        }

        .fa-times {
            themed: color reject;
        }
    }
}
