#ViewReport {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 100%;
    clear: both;

    textarea {
    }

    .hide {
        visibility: hidden;
    }

    .users-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .reporting-user, .reported-user {
            white-space: nowrap;
        }

        .reported-user {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;

            > span {
                margin-left: 0.5rem;
            }
        }

        .reported-user-banned {
            font-size: smaller;
            font-style: italic;
            padding-left: 0.4rem;
            themed: color info;
        }

        .users-header-right {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .moderator-selector > span {
                padding-right: 0.5rem;
            }

            #ViewReportSelectModerator {
                font-size: 0.8rem;
                font-weight: 400;
                display: inline-block;
                width: 10rem;

                .reports-center-assigned-moderator-container {
                    display: inline-flex;
                    align-items: center;
                    padding-left: 0.5rem;
                }

                // Force moderator selector to be vertically small:
                // get rid of padding and min-height of internals
                .ogs-react-select__control {
                    min-height: 0;

                    .ogs-react-select__dropdown-indicator {
                        padding: 0.2rem 0.5rem 0.2rem 0.5rem;
                    }
                }
            }

            .reporting-user {
                font-size: 0.8rem;
                font-weight: 400;
            }
        }
    }

    .source-to-target-languages {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        themed: border-color shade3;
        text-align: center;
    }

    .translated {
    }

    .no-report-selected {
        text-align: center;
        margin-top: 10rem;
        margin-bottom: 1rem;
        font-size: 3rem;
    }

    .historical-report-number {
        font-size: 1.1rem;
        padding-right: 1rem;
    }

    .when {
        margin-left: 0.8rem; // this is the font size in this span
    }

    .newer-older-buttons {
        display: flex;
        width: 16.5rem;
        justify-content: space-between;
        margin-bottom: 1rem;

        button {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .related-reports {
        ul li {
            font-size: smaller; // so that reports related three ways don't wrap
        }
    }

    .actions {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;

        .actions-left {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }

        .actions-right {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: flex-end; // put the buttons down close-ish to the send-warning buttons

            button {
                height: auto;
                padding: 7px;
            }
        }
    }

    .voters {
        h4 {
            margin-bottom: 0;
        }

        ul {
            margin-top: 0;
        }
    }

    .community-mod-note {
        h5 {
            margin: 0;
        }

        .Card {
            font-size: smaller;
            margin-bottom: 0; // aligns with items on the right
        }
    }

    .automod-analysis {
        margin-bottom: 1rem;

        .analysis {
            font-style: italic;
        }
    }

    .message-templates {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        flex-wrap: wrap;

        button {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .notes {
        flex-basis: 50%;
        white-space: pre-wrap;

        .mod-note-save {
            height: unset;
        }

        .new-mod-note {
            flex: unset;
        }
    }

    .voting {
        flex-basis: 50%;

        .action-selector {
            display: flex;
            align-items: center;
            padding: 0.3rem;

            input {
                margin-bottom: 3px; // override bizarre default 0 margin bottom so text lines up properly
            }

            .vote-count {
                font-size: x-small;
                vertical-align: middle;
                padding-left: 5px;
            }
        }

        button {
            margin-top: 0.5rem;
        }
    }
}

@media (max-width: 767px) {
    #ViewReport {
        .newer-older-buttons {
            display: inline-flex;
            width: 100%;
            max-width: 100vw;
        }

        .voting {
            flex-basis: 100%;
        }
    }
}
