/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */

.AccessibilityMenu {
    themed: background-color navbar-background-color;
    box-shadow: 2px 4px 3px 0px rgba(0, 0, 0, 0.16);

    position absolute;
    overflow: hidden;

    z-index: 0;
    margin: -1px;
    width: 1px;
    height: 1px;

    &:focus-within {
        z-index: 9999;
        margin: .3rem;
        width: initial;
        padding: 3px;
        height: initial;
    }

    a {
        padding: .6rem;
        display: block;

        &:focus {
            outline: 2px solid #2480ff;
        }
    }
}
