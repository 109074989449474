reports_center_content_width = 56rem;

.ReportsCenter {
    display: flex;
    flex-direction: column;

    .ModTools {
        margin: 0;
        themed: background bg;
        border: 0;
        box-shadow: none;
        max-width: reports_center_content_width;
    }

    .moderator {
        button.danger {
            margin-left: 0.5rem !important;
            margin-right: 0.5rem !important;
        }
    }

    #ReportsCenterSelectReport {
        display: inline-block;
        width: 6rem;

        .reports-center-selected-report-container {
            display: inline-flex;
            align-items: center;
        }

        margin-right: 0.5rem;
    }

    .ModerationActionSelector {
        display: flex;
        flex-direction: column;
        padding-bottom: 0.5rem; // intended to create margin for rdh highlighter

        .no-report-actions-note, .disabled-actions-note {
            padding: 0 1rem 1rem 1rem;
        }

        button {
            max-width: fit-content;
            align-self: center;
        }
    }

    #leave-moderator-note {
        textarea#moderator-note {
            width: 100%;
        }
    }

    .notes-container {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: stretch;
        justify-content: stretch;
        align-content: stretch;
    }

    .notes {
        margin-bottom: 1rem;
        flex: 1;
        flex-basis: 40%;
        min-width: 20rem;
        display: inline-flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: stretch;
        align-content: stretch;

        .Card, textarea {
            flex: 1;
            margin-left: 1rem;
            margin-right: 1rem;
            margin-top: 0;
            margin-bottom: 0;
            min-height: 4rem;
        }

        textarea {
            flex: 1;
        }
    }

    h3, h4 {
        margin-top: 0;
    }

    .created-note {
        margin-left: 1rem;
        font-size: smaller;
        font-weight: normal;
    }

    .progress {
        margin-bottom: 0.5rem;
    }

    .progress-bar.empty {
        themed: background-color shade5;
    }
}

#ReportsCenterContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    // align-items: stretch;
    align-items: flex-start;
}

#ReportsCenterCategoryList {
    border: 1px solid black;
    themed: border-color shade3;
    flex-basis: 15rem;
    min-width: 15rem;
    max-width: 15rem;

    .Category {
        cursor: pointer;
        padding: 0.7rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
            themed: background-color shade5;
        }

        &.selected {
            themed: background-color primary;
            themed: color colored-background-fg;
        }

        .spacer {
            display: inline-block;
            width: 1rem;
        }

        i {
            display: inline-block;
            width: 1rem;
        }
    }
}

#ViewReport {
    flex: 1;

    .help-details-greyed {
        themed: color shade4;
    }
}

// Mobile
@media (max-width: 767px) {
    #ReportsCenterCategoryList {
        display: none;
    }

    #ReportsCenterContainer {
        flex-direction: column;
    }

    #ViewReport {
        padding-top: 1rem;
    }

    #ReportsCenterCategoryDropdown {
        .reports-center-category-container {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    #ReportsCenterContainer {
        align-items: stretch;
    }
}

// Desktop
@media (min-width: 768px) {
    #ReportsCenterCategoryDropdown {
        display: none;
    }

    #ViewReport {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
