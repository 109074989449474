.ReportsViewer {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 100%;
    clear: both;

    .hide {
        visibility: hidden;
    }

    .view-report-header {
        margin-left: 1rem;
        margin-bottom: 1rem;

        .report-id {
            margin-right: 1rem;
        }

        button {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
        }

        .reports-center-selected-report {
            padding-left: 0.5rem;
        }
    }
}